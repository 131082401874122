.container {
  display: flex;
  flex-direction: column;
}

.controlBar {
  width: 100%;
}

.dateContainer {
  display: flex;
  flex-direction: row;
}

.dateCellBlank {
  width: 64px;
  height: 27px;
  border-bottom: 1px solid #C2C5CA;
}

.dateCell {
  width: 160px;
  height: 27px;
  font-size: 12px;
  color: #1F2329;
  border-bottom: 1px solid #C2C5CA;
}

.dateTimeCell {
  width: 160px;
  height: 60px;
  flex: 0 0 160px;
}

.timeLineContainer {
  display: flex;
  flex-direction: column;
}

.timeLineCell {
  height: 60px;
  width: 64px;
  font-size: 12px;
  color: #1F2329;
}

.dateTimeContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

.row {
  display: flex;
  flex-direction: row;
}

.dateTimeCell {
  width: 160px;
  height: 60px;
  border-left: 1px solid #E8E8E8;
  border-bottom: 1px solid #E8E8E8;
  cursor: pointer;
}