.header {
  background-color: #3C4A73;
  padding: 11px 0px 12px 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo {
  height: 40px;
}

.userInfoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 24px;
}

.userInfoContainerInner {
  display: flex;
  flex-direction: column;
  padding-right: 42px;
}

.corpName {
  color: #FFFFFF;
  font-size: 14px;
  height: 25px;
  line-height: 25px;
}

.positionName {
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  background-color: #E2EBFF;
  border-radius: 3px;
  padding: 0 6px;
  margin-top: 3px;
  transform: scale(0.9) translateX(-2px);
}

.iconDown {
  width: 12px;
}
