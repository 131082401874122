.my-select {
  flex: 1;

  .ant-select-selector {
    border: none!important;
  }
  .ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active {
    border: none!important;
    box-shadow: none!important;
  }
}