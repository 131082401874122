.btnNext {
  width: 100%;
  margin-top: 8px;
  margin-bottom: 12px;
}

.leftPanel {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: #FFF;
}

.rightPanel {
  height: 100vh;
  > img {
    height: 100%;
  }
}
